import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'actions';
import Selectors from 'selectors';
import { TextInput, SelectInput, SingleCheckboxInput } from 'components/Form';
import { Field, Formik, Form } from 'formik';
import classnames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import { ALL_ACCESS } from 'utils/constants';
import { debounce } from 'lodash';

const FilterForm = ({
  setFieldValue,
  setFieldTouched,
  submitForm,
  values,
  resetForm,
}) => {
  const dispatch = useDispatch();
  const [, setSearchParams] = useSearchParams();
  const hasParams = Object.values(values).some((obj) => obj !== '');
  const isMountedRef = useRef(false);
  const [hasFetchedOptions, setFetchedOptions] = useState(false);

  useEffect(() => {
    if (!hasFetchedOptions) {
      dispatch(Actions.fetchMemberTiers());
      dispatch(Actions.fetchMemberTypes({ has_users: true }));
      dispatch(Actions.fetchCountries({ has_users: true }));
      dispatch(Actions.fetchCities({ has_users: true, simple: true }));
      setFetchedOptions(true);
    }
  }, [dispatch, hasFetchedOptions]);

  useEffect(() => {
    if (hasParams) {
      if (!isMountedRef.current) {
        isMountedRef.current = true;
      }
      submitForm();
    }
  }, [hasParams, submitForm]);

  const isLoading = useSelector(
    Selectors.createLoadingSelector([
      Actions.FETCH_COUNTRIES_REQUEST,
      Actions.FETCH_CITIES_REQUEST,
      Actions.FETCH_MEMBER_TIERS_REQUEST,
      Actions.FETCH_MEMBER_TYPES_REQUEST,
    ])
  );

  const countries = useSelector(Selectors.countries);
  const cities = useSelector(Selectors.cities);
  const memberTypes = useSelector(Selectors.memberTypesList);
  const memberTiers = useSelector(Selectors.memberTiersList);
  const memberTierIds = memberTiers.map(({ id }) => id);
  const proId = memberTiers.find(({ name }) => name === ALL_ACCESS)?.id;

  const commonSelectProps = {
    component: SelectInput,
    getOptionLabel: ({ name }) => name,
    getOptionValue: ({ id }) => id,
  };

  const updateSearch = useCallback(
    debounce(() => {
      submitForm();
    }, 500),
    []
  );

  return (
    <Form
      onChange={(e) => {
        submitForm(e);
      }}
      className="flex flex-col justify-center mt-6"
      autoComplete="off">
      <div className="flex items-center relative z-10">
        <Field
          name="name"
          onChange={(value, e) => {
            e.preventDefault();
            e.stopPropagation();
            updateSearch();
            setFieldValue('name', value);
          }}
          className="h-12 rounded-full shadow-md"
          fieldClassName="flex-1"
          component={TextInput}
          basic
          placeholder="Search by name"
        />
        <div className="ml-3 sm:ml-8 text-center">
          {hasParams && (
            <button
              className="border border-silver rounded shadow text-silver focus:outline-none px-4 py-2 w-full sm:w-32 mr-2 mb-4 sm:mb-0"
              onClick={() => {
                resetForm();
                setSearchParams();
              }}
              type="button">
              Clear Filter
            </button>
          )}
        </div>
      </div>
      <div
        className={classnames(
          'flex flex-col md:flex-row flex-wrap justify-between transition-all duration-400 ease-linear',
          'mt-6 sm:mt-0 opacity-100'
        )}>
        <Field
          name="country_id"
          className="flex-1 sm:mr-2 lg:mr-4"
          isLoading={isLoading}
          options={countries}
          placeholder="Country"
          onChange={({ id }) => {
            dispatch(
              Actions.fetchCities({
                has_users: true,
                simple: true,
                country_id: id,
              })
            );
            setFieldValue('city', undefined);
            setFieldTouched('city', false);
            setFieldValue('country_id', id);
            submitForm();
          }}
          {...commonSelectProps}
        />
        <Field
          name="city"
          className="flex-1 lg:mr-4"
          isLoading={isLoading}
          options={cities}
          placeholder="City"
          onChange={({ id }) => {
            setFieldValue('city', id);
            submitForm();
          }}
          {...commonSelectProps}
        />
        <Field
          name="member_type_id"
          className="flex-1 lg:mr-4"
          isLoading={isLoading}
          options={memberTypes}
          placeholder="Role / Occupation"
          onChange={({ id }) => {
            setFieldValue('member_type_id', id);
            submitForm();
          }}
          {...commonSelectProps}
        />
        <Field
          name="member_tier_id"
          className="flex justify-center items-center"
          component={SingleCheckboxInput}
          label={`${ALL_ACCESS} members`}
          options={memberTierIds}
          nonBooleanValue={proId}
          onChange={submitForm}
          hideError
        />
      </div>
    </Form>
  );
};

FilterForm.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

const Filter = () => {
  const [, setSearchParams] = useSearchParams();
  const setQueryParam = (v) => {
    const result = {};
    Object.keys(v).forEach((k) => {
      if (v[k]) result[k] = v[k];
      if (k === 'name') result[k] = v[k].trim();
    });
    setSearchParams(result);
  };
  const {
    name,
    country_id: countryId,
    city,
    member_tier_id: memberTierId,
    member_type_id: memberTypeId,
  } = useQuery();

  return (
    <Formik
      onSubmit={setQueryParam}
      initialValues={{
        name: name || '',
        country_id: countryId || '',
        city: city || '',
        member_tier_id: memberTierId || '',
        member_type_id: memberTypeId || '',
      }}
      enableReinitialize>
      {(formikProps) => <FilterForm {...formikProps} />}
    </Formik>
  );
};

export default Filter;
