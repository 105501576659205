import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import classnames from 'classnames';
import styled from '@emotion/styled';
import FieldContainer from 'components/Form/FieldContainer';

const fieldStyles = `
  transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  &::-webkit-input-placeholder {
    visibility: visible !important;
  }
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 96%,
    #47bad4 4%
  );
  &::-webkit-input-placeholder {
    transition: all 0.3s ease-in-out;
  }
  &:placeholder-shown {
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 96%,
      #ececec 4%
    );
  }
  &:focus {
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 96%,
      #47bad4 4%
    );
    &::-webkit-input-placeholder {
      color: #47bad4;
      font-size: 11px;
      transform: translateY(-19px);
      visibility: visible !important;
    }
  }
`;

const StyledInput = styled.input`
  ${({ basic }) => !basic && fieldStyles}
`;
const StyledTextArea = styled.textarea`
  ${({ basic }) => !basic && fieldStyles}
`;

const TextInput = (props) => {
  const {
    field: { name, value },
    form: { errors, handleBlur, setFieldValue, touched },
    placeholder,
    type,
    className,
    label,
    basic,
    fieldClassName,
    maxLength,
    showRemaining,
    onChange,
  } = props;

  const errorMessage = getIn(touched, name) && getIn(errors, name);

  const onValueChange = (e) => {
    const { value } = e.target;

    setFieldValue(name, value);
    if (onChange) onChange(value, e);
  };

  const inputProps = {
    ...props,
    className: classnames(
      'appearance-none focus:outline-none leading-tight px-3 text-gray-600 w-full',
      className,
      basic ? 'border py-2 rounded shadow' : 'outline-none pb-2 pt-4',
      { 'mt-2': type === 'textarea' }
    ),
    id: name,
    name,
    onChange: onValueChange,
    onBlur: handleBlur,
    placeholder,
    type,
    value,
    basic,
    maxLength,
  };
  delete inputProps.form;

  const input =
    type === 'textarea' ? (
      <StyledTextArea {...inputProps} />
    ) : (
      <StyledInput {...inputProps} />
    );

  return (
    <FieldContainer
      placeholder={placeholder}
      label={label}
      error={errorMessage}
      name={name}
      value={value}
      basic={basic}
      fieldClassName={fieldClassName}>
      {input}
      {showRemaining && (
        <i className="block text-right text-dusty text-sm">
          {maxLength - value.length} characters left
        </i>
      )}
    </FieldContainer>
  );
};

TextInput.propTypes = {
  label: PropTypes.string,
  form: PropTypes.any.isRequired,
  field: PropTypes.any.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  basic: PropTypes.bool,
  showRemaining: PropTypes.bool,
  fieldClassName: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
};

TextInput.defaultProps = {
  label: '',
  placeholder: '',
  type: 'text',
  className: null,
  basic: false,
  showRemaining: false,
  fieldClassName: null,
  maxLength: null,
  onChange: null,
};

export default TextInput;
